module.exports = new Promise(resolve => {

        // GET appConfig
        if(!window.appConfig) {
            var getApiResult = function(method, url)
            {
                var xhr = new XMLHttpRequest();
                xhr.open(method, url, false);
                xhr.send();
                return JSON.parse(xhr.responseText);
            }
            window.appConfig = getApiResult("GET", "/appConfig.json");
        }

        // Specify domains
        var url = window.appConfig?.packages['ui-catalyst'].uri;
        var fallBackUrl = '';
        if(undefined) {
            fallBackUrl = window.appConfig?.undefined;
        }

        // Try load first domain script
        var script = document.createElement('script');
        script.src = url;
        document.head.appendChild(script);

        script.onload = () => {
            const proxy = {
            get: (request) => {
                return window['ui'].get(request);
            },
            init: (arg) => {
                try {
                        return window['ui'].init(arg)
                    } catch(e) {
                        console.log('remote container already initialized')
                    }
                }
            }
            resolve(proxy)
        }
        script.onerror = (error) => {
            if(fallBackUrl) {
                document.head.removeChild(script);

                // Try load fallback domain script
                return triggerFallbackScript();
            }
            else {
                const proxy = {
                  get: (request) => {
                    // If the service is down it will render this content
                    return Promise.resolve(() => () => "");
                  },
                  init: (arg) => {
                    return;
                  }
                }
                resolve(proxy)
            }
        }

        function triggerFallbackScript() {
            var fallbackScript = document.createElement('script');
            fallbackScript.src = 'fallBackUrl';
            document.head.appendChild(fallbackScript);

            fallbackScript.onload = () => {
                const proxy = {
                get: (request) => {
                    return window['ui'].get(request);
                },
                init: (arg) => {
                    try {
                            return window['ui'].init(arg)
                        } catch(e) {
                            console.log('remote container already initialized')
                        }
                    }
                }
                resolve(proxy)
            }
            fallbackScript.onerror = (error) => {
                const proxy = {
                  get: (request) => {
                    return Promise.resolve(() => () => "");
                  },
                  init: (arg) => {
                    return;
                  }
                }
                resolve(proxy)
            }
        }
    });